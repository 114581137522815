
export class AppSettings {

    // TEST ENV

    //  public static baseURL = 'http://localhost:8000/api/';
    //  public static loginUrl = 'http://localhost:8000/auth/login';
    //  public static apiBaseUrl = 'http://localhost:8000/';

    //TEST ENV - PUBLIC

     public static baseURL = 'https://asset-api.mlimaniholdingsltd.com/'; // local development
     public static loginUrl = 'https://asset-api.mlimaniholdingsltd.com/public/api/auth/login';
     public static apiBaseUrl = 'https://asset-api.mlimaniholdingsltd.com/public/';

    //  public static baseURL = 'https://asset-api.c/'; // local development
    //  public static loginUrl = 'https://asset-api.digilabs.co.tz/public/api/auth/login';
    //  public static apiBaseUrl = 'https://asset-api.digilabs.co.tz/public/';

    public static isLoggedInKey = 'e2c7c640-cbe3-4d51-a60b-404ee3622a85';
    public static jwtTokenKey = '54736b14-a232-46e5-a53d-d82b43edbefa';
    public static jwtDecodedKey = 'e2c7c640-cbe3-4d51-a53d-d82b43edbefa';

    public static OTPkey = '8623283';

    public static XPR_TOS_PST_TEST = '985243$#@$^@1ERF';

    public static hidePurchaseOrderFormKey = 'jkhbkjbghktr';
    public static hideSalesOrderFormKey = 'dfvgtfgttd';
    public static hideDeiveryNoteFormKey = 'fgngninkrnuigt';

    public static tempKey = 'dfligjoutnujutivtr';
    public static tempKey1 = 'djihduidfgvbbfvyubcd';
    public static salesKey = 'dukhfuigdyugekitffgfe78r854547855';

}
